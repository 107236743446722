
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  NavLink,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useParams,
  useRouteError
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { MessageCircle, Terminal } from "lucide-react";
import "./styles/fontface.css";

import Header from "@/components/Header/Header";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Role } from "@prisma/client";
import { useEffect } from "react";
import { getUser } from "~/session.server";
import { countUnreadNotesForUser } from "./models/note.server";
import { getUserRoles } from "./models/user.server";
import "./styles/tailwind.css";

export const meta: MetaFunction = () => [
  {
    title: "Amanda AI",
  },
];

export const links: LinksFunction = () => {
  return [
    // add favicon
    {
      rel: "icon",
      href: "/favicon.svg",
      type: "image/svg+xml",
    },
  ];
  // return [{ rel: "icon", href: "https://c1.webien.cloud/amandaai.com-stage/app/uploads/2022/08/favicon-logo-ornage.svg", type: "image/svg+xml" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const environment = process.env.NODE_ENV;

  // fetch build number
  const fs = await import('fs');
  const path = await import('path');
  const filePath = path.join(process.cwd(), 'buildNumber.txt');
  let buildNumber = "";

  if (fs.existsSync(filePath)) {
    buildNumber = String(fs.readFileSync(filePath, 'utf8'));
  }

  const url = new URL(request.url);
  const pathName = url.pathname
  const user = await getUser(request);

  if (!user && pathName !== "/login" && !/^\/recover/.test(pathName)) {
    throw redirect("/login");
  }

  let isAdmin = false;
  let undreadNotesCount = 0;
  let userRoles: Role[] | undefined = [];
  if (user) {
    userRoles = await getUserRoles(user?.id);
    if (userRoles && (userRoles.includes(Role.ADMIN) || userRoles.includes(Role.PARTNERADMIN) || userRoles.includes(Role.SUPERUSER))) {
      isAdmin = true;
    }
    undreadNotesCount = await countUnreadNotesForUser({ userId: user?.id });
  }

  return json(
    {
      ENV: { NODE_ENV: environment },
      user,
      isAdmin,
      undreadNotesCount,
      buildNumber
    },
  );
};

function App() {
  const { ENV, user, isAdmin, undreadNotesCount, buildNumber } = useLoaderData<typeof loader>();
  const params = useParams();
  const organizationId = params?.organizationId;
  const location = useLocation();
  const pathname = location.pathname;
  const isOnboarding = pathname.includes("/onboarding");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "96256389-0601-4343-bc53-131037df4323");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <html lang="en" className="">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <script
          suppressHydrationWarning
          data-cookieconsent="ignore"
          dangerouslySetInnerHTML={{
            __html:
              `window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('consent', 'default', {
                'ad_personalization': 'denied',
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'analytics_storage': 'denied',
                'functionality_storage': 'denied',
                'personalization_storage': 'denied',
                'security_storage': 'granted',
                'wait_for_update': 500,
            });
            gtag("set", "ads_data_redaction", true);
            gtag("set", "url_passthrough", false);`
          }}
        />
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `<!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KF2QRNQ');
            <!-- End Google Tag Manager -->`
          }}
        />
      </head>
      <body className="bg-black flex flex-col gap-0 space-y-0 box-content m-0 p-0 min-h-screen h-full">
        <noscript>
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KF2QRNQ"
            height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
          </iframe>
        </noscript>
        {user ? (
          <Header user={user}>
            {!isOnboarding ? (
              <div className="mr-2 hidden space-x-3 md:flex items-center">
                {isAdmin ? (
                  <NavLink
                    to={"/office"}
                    key="office"
                    className={({ isActive }) =>
                      `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                        ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                        : ""
                      }`
                    }
                    viewTransition
                    prefetch="intent"
                  >
                    Office
                  </NavLink>)
                  : null
                }
                <NavLink
                  to={organizationId ? `/projects/${organizationId}` : `/projects`}
                  key="projects"
                  className={({ isActive }) =>
                    `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                      ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                      : ""
                    }`
                  }
                  viewTransition
                  prefetch="intent"
                >
                  Projects
                </NavLink>
                <NavLink
                  to="drafts"
                  key="drafts"
                  className={({ isActive }) =>
                    `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                      ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                      : ""
                    }`
                  }
                  viewTransition
                  prefetch="intent"
                >
                  Drafts
                </NavLink>
                {isAdmin ? (
                  <div className="relative mr-1 flex items-center gap-5">
                    <div>
                      <Link to="office/notes" viewTransition prefetch="intent" className="group">
                        <MessageCircle size={22} className="group-hover:text-hausorange transition-all duration-500" />
                        {undreadNotesCount > 0 ? (
                          <div className="absolute -top-1 -right-1 flex text-xs h-4 w-4 bg-hausorange rounded-full items-center justify-center transform-gpu transition-transform duration-300 delay-75 ease-in group-hover:animate-bounce">
                            {undreadNotesCount}
                          </div>)
                          : null}
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            ) :
              null
            }
          </Header>
        ) : null}
        <main className="bg-hausbeigelight h-full flex flex-col grow">
          <Outlet />
        </main>
        <footer className="bg-black h-full z-50 flex flex-col grow-0">
          {!user ? (
            <div></div>
          ) : null}
          <div className="text-white text-center pt-5 pb-7">
            <div className="text-xs font-thin">Amanda AI</div>
            <div className="text-xs font-thin text-hausorange tracking-wide">Dance to the algorithms!</div>
            <div className="mr-1  opacity-80 text-[8pt] font-thin select-none text-muted-foreground">{(`${buildNumber}`)}</div>
          </div>
        </footer>
        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)};`,
          }}
        />
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof Error) {
    return (
      <div className="h-screen">
        <Alert variant="destructive">
          <Terminal className="h-4 w-4" />
          <AlertTitle>An unexpected error occurred</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      </div>
    );
  }

  if (!isRouteErrorResponse(error)) {
    return (
      <Alert variant="destructive">
        <Terminal className="h-4 w-4" />
        <AlertTitle>An unknown error occurred</AlertTitle>
      </Alert>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>An unexpected error occurred</AlertTitle>
      <AlertDescription>{error.data}</AlertDescription>
    </Alert>
  );
}
